import { createContext } from 'react';

const SesionContext = createContext({
  usuario: null,
  setUsuario: () => {},
  role: null,
  setRole: () => {},
});

export default SesionContext;
