import http from '../utils/http';

export const getCatalogosService = async () => {
  try {
    const { data } = await http.get('CatalogoItem');
    return data;
  } catch (ex) {
    throw ex;
  }
};
