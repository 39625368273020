import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import '../styles/components/Loading.scss';

export default function Loading(props) {
  const { titulo } = props;
  return (
    <div className="loading">
      <Spinner className="loading__spinner" variant="primary" animation="border" role="status" />
      <p className="loading__titulo">{titulo}</p>
    </div>
  );
}

Loading.defaultProps = {
  titulo: 'Cargando...',
};

Loading.propTypes = {
  titulo: PropTypes.string,
};
