import http from '../utils/http';

export const getDetalleReporteService = async rangoFechas => {
  try {
    const { data } = await http.get('Bitacora/ReporteByDates', {
      params: {
        inicio: rangoFechas.fechaDesde,
        fin: rangoFechas.fechaHasta,
      },
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};
