import http from '../utils/http';

export const getBitacorasService = async () => {
  try {
    const { data } = await http.get('Bitacora');
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getBitacorasDateService = async date => {
  try {
    const { data } = await http.get('Bitacora/ByDate', { params: { date: date?.date } });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const addBitacorasService = async bitacora => {
  try {
    const { data } = await http.post('Bitacora', bitacora);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteBitacorasService = async idItem => {
  try {
    const { data } = await http.delete('Bitacora/DeleteById', {
      params: {
        id: idItem,
      },
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};
