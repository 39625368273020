import PropTypes from 'prop-types';
import classNames from 'classnames';

import logotipo from '../../assets/images/logotipo.png';

import '../../styles/components/ReporteTemplate.scss';

export default function Reporte(props) {
  const { titulo, subtitulo, data } = props;
  return (
    <div className="reporte-template">
      <div className="reporte-template__cabecera">
        <img className="reporte-template__logotipo" src={logotipo} alt="logotipo" />
        <div className="reporte-template__titulos">
          <p className="reporte-template__titulo">{titulo}</p>
          <p className="reporte-template__subtitulo">{subtitulo}</p>
        </div>
      </div>
      <div className="reporte-template__lista">
        <div className="reporte-template__contenedor-item">
          <p className="reporte-template__item--azul">Item</p>
          <p className="reporte-template__item--azul">Cant.</p>
        </div>
        {data.items.length > 0 && (
          <>
            {data.items.map((item, index) => (
              <div
                key={`${item.descripcion}-${index}`}
                className={classNames('reporte-template__contenedor-item', {
                  'reporte-template__contenedor-item--gris': (index + 1) % 2 === 0,
                })}
              >
                <p className="reporte-template__item">{item.descripcion}</p>
                <p className="reporte-template__item px-4">{item.cantidad}</p>
              </div>
            ))}
          </>
        )}
        <div className="reporte-template__contenedor-item">
          <p className="reporte-template__item--azul">Total Servicios</p>
          <p className="reporte-template__item--azul px-4">{data.total}</p>
        </div>
      </div>
      <div className="reporte-template__footer">
        <p className="reporte-template__direccion">
          Calzada del Campesino #265, San Pablo C.P. 28060, Colima, Colima, México
        </p>
        <p className="reporte-template__telefonos">
          <span className="reporte-template__telefono-item">TELEFONOS</span>{' '}
          <span className="reporte-template__telefono-item">+52 (312) 312 33 83</span>{' '}
          <span className="reporte-template__telefono-item">+52 (312) 312 80 98</span>
        </p>
      </div>
    </div>
  );
}

Reporte.propTypes = {
  titulo: PropTypes.string.isRequired,
  subtitulo: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
