import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useSesion from '../hooks/useSesion';
import { routes } from '../constants/routes';
import { ROLES } from '../constants/roles';
import Brand from '../assets/images/brand.png';
import { logoutService } from '../services/auth.service';

export default function NavMenu() {
  const { setUsuario, setRole, role } = useSesion();

  return (
    <Navbar className="text-white px-3" bg="primary" expand="lg" variant="dark" sticky="top" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand>
          <img src={Brand} alt="Aleante Ingenierías" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100">
            <Nav.Link as={Link} to={routes.bitacora}>
              Bitácora
            </Nav.Link>
            <Nav.Link as={Link} to={routes.revisiones}>
              Revisiones
            </Nav.Link>
            {role === ROLES.ADMINISTRADOR && (
              <Nav.Link as={Link} to={routes.reportes}>
                Reportes
              </Nav.Link>
            )}
            <Nav.Link onClick={() => logoutService(setUsuario, setRole)}>Cerrar Sesión</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
