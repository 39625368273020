import { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Title from '../components/Title';
import { routes } from '../constants/routes';

export default function Reportes() {
  const [filtros, setFiltros] = useState({
    fechaDesde: new Date().toISOString().slice(0, 10),
    fechaHasta: new Date().toISOString().slice(0, 10),
  });
  const navigate = useNavigate();

  const redireccionarDetalle = () => {
    navigate(routes.detalleReporte, {
      state: {
        fechaDesde: filtros.fechaDesde,
        fechaHasta: filtros.fechaHasta,
      },
    });
  };

  return (
    <Container className="avance d-flex flex-column">
      <Title titulo="Reporte Bitácoras" />
      <Row className="px-2 mt-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4>Generar Reporte</h4>
        </Col>
      </Row>
      <Row className="px-2 my-2">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <Form.Control
              value={filtros.fechaDesde}
              onChange={({ target }) => setFiltros(filt => ({ ...filt, fechaDesde: target.value }))}
              name="fechaDesde"
              type="date"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="px-2 my-2">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Group>
            <Form.Label>Hasta</Form.Label>
            <Form.Control
              value={filtros.fechaHasta}
              onChange={({ target }) => setFiltros(filt => ({ ...filt, fechaHasta: target.value }))}
              name="fechaHasta"
              type="date"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="px-2 my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button className="text-white w-100" variant="primary" onClick={() => redireccionarDetalle()}>
            Visualizar Reporte
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
