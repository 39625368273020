import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { es } from 'date-fns/locale';
import { useReactToPrint } from 'react-to-print';

import Title from '../components/Title';
import Loading from '../components/Loading';
import Reporte from '../components/templates/Reporte';
import useError from '../hooks/useError';
import { routes } from '../constants/routes';
import { getDetalleReporteService } from '../services/reporte.service';

import '../styles/DetalleReporte.scss';

export default function DetalleReporte() {
  const [cargando, setCargando] = useState(false);
  const [detalleBitacoras, setDetalleBitacoras] = useState({
    total: 0,
    items: [],
  });
  const refPlantilla = useRef(null);
  const pdfGenerador = useReactToPrint({
    content: () => refPlantilla.current,
    documentTitle: `Reporte`,
  });
  const location = useLocation();
  const { setError, ErrorAlert } = useError();

  const obtenerDetalleReporte = async () => {
    try {
      setCargando(true);
      const response = await getDetalleReporteService({
        fechaDesde: new Date(location.state.fechaDesde),
        fechaHasta: new Date(location.state.fechaHasta),
      });
      setDetalleBitacoras(response);
      generarReportePDF(response);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando(false);
    }
  };

  //i Se llama al cargar la vista para poder generar el reporte
  const generarReportePDF = dataBitacoras => {
    const pdfTemplate = (
      <div className="d-flex align-items-center justify-content-between flex-wrap" ref={refPlantilla}>
        <Reporte
          titulo="Reporte Bitácoras"
          subtitulo={`${formatInTimeZone(location.state.fechaDesde, 'America/Mexico_City', 'dd MMMM yyyy', {
            locale: es,
          })} - ${formatInTimeZone(location.state.fechaHasta, 'America/Mexico_City', 'dd MMMM yyyy', {
            locale: es,
          })}`}
          data={dataBitacoras}
        />
      </div>
    );
    ReactDOM.render(pdfTemplate, document.querySelector('#pdf-container'));
  };

  useEffect(() => {
    obtenerDetalleReporte();
  }, []);

  return (
    <Container className="detalle-reporte d-flex flex-column">
      {cargando && <Loading titulo="Obteniendo detalle reporte" />}
      <ErrorAlert />
      <Title titulo="Reporte Bitácoras" ruta={routes.reportes} />
      <div className="d-none" id="pdf-container"></div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h5 className="text-center mt-2">
            {formatInTimeZone(location.state.fechaDesde, 'America/Mexico_City', 'dd MMMM yyyy', {
              locale: es,
            })}{' '}
            -{' '}
            {formatInTimeZone(location.state.fechaHasta, 'America/Mexico_City', 'dd MMMM yyyy', {
              locale: es,
            })}
          </h5>
        </Col>
      </Row>
      <Row className="px-2 mt-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="detalle-reporte__contenedor-item">
            <p className="detalle-reporte__item--azul">Items</p>
            <p className="detalle-reporte__item--azul">Cantidad</p>
          </div>
          {detalleBitacoras.items.length > 0 ? (
            <>
              {detalleBitacoras.items.map(item => (
                <div className="detalle-reporte__contenedor-item">
                  <p className="detalle-reporte__item">{item.descripcion}</p>
                  <p className="detalle-reporte__item px-4">{item.cantidad}</p>
                </div>
              ))}
            </>
          ) : (
            <p>No hay items registrados</p>
          )}
          <div className="detalle-reporte__contenedor-item">
            <p className="detalle-reporte__item--azul">Total Servicios</p>
            <p className="detalle-reporte__item--azul px-4">{detalleBitacoras.total}</p>
          </div>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button
            className="w-100 text-white"
            variant="primary"
            onClick={pdfGenerador}
            disabled={detalleBitacoras.items.length < 1}
          >
            Generar Reporte
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
