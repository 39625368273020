import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROLES } from './constants/roles';
import { routes } from './constants/routes';
import Login from './pages/Login';
import Bitacora from './pages/Bitacora';
import Revisiones from './pages/Revisiones';
import Reportes from './pages/Reportes';
import DetalleReporte from './pages/DetalleReporte';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import SesionContext from './context/sesionContext';

import './styles/App.scss';

function App() {
  const [usuario, setUsuario] = useState(JSON.parse(localStorage.getItem('credenciales'))?.usuario);
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('credenciales'))?.roles[0].toLowerCase());
  const contextData = {
    usuario,
    setUsuario,
    role,
    setRole,
  };

  const navegacion = pagina => {
    if (!!usuario) {
      return pagina;
    } else {
      return <Navigate to={routes.root} />;
    }
  };

  return (
    <SesionContext.Provider value={contextData}>
      <main className="d-flex flex-column justify-content-between">
        {!!usuario && <NavMenu />}
        <div className="flex-grow-1">
          <Routes>
            <Route path={routes.root} element={!!usuario ? <Navigate to={routes.bitacora} /> : <Login />} />
            <Route path={routes.bitacora} element={navegacion(<Bitacora />)} />
            <Route path={routes.revisiones} element={navegacion(<Revisiones />)} />
            <Route
              path={routes.reportes}
              element={navegacion(role === ROLES.ADMINISTRADOR ? <Reportes /> : <Navigate to={routes.bitacora} />)}
            />
            <Route
              path={routes.detalleReporte}
              element={navegacion(
                role === ROLES.ADMINISTRADOR ? <DetalleReporte /> : <Navigate to={routes.bitacora} />
              )}
            />
          </Routes>
        </div>
        <Footer />
      </main>
    </SesionContext.Provider>
  );
}

export default App;
