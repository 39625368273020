import '../styles/components/Footer.scss';

export default function Footer() {
  return (
    <div className="footer">
      <p className="text-center my-3">
        Aleante Ingenierías &copy; {new Date().getFullYear()} | Todos los derechos reservados.
      </p>
    </div>
  );
}
