import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Tabs, Tab, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

import Title from '../components/Title';
import useError from '../hooks/useError';
import Loading from '../components/Loading';
import { routes } from '../constants/routes';
import { getCatalogosService } from '../services/catalogos.service';
import { addBitacorasService, getBitacorasDateService } from '../services/bitacoras.service';

import '../styles/Revisiones.scss';

export default function Revisiones() {
  const [fecha, setFecha] = useState(new Date().toISOString().slice(0, 10));
  const [cargando, setCargando] = useState({
    estado: false,
    mensaje: '',
  });
  const [pestana, setPestana] = useState('primero');
  const [items, setItems] = useState({
    primerTurno: [],
    segundoTurno: [],
  });
  const { ErrorAlert, setError } = useError();
  const navigate = useNavigate();
  const location = useLocation();
  const obtenerItems = async () => {
    try {
      setCargando({
        estado: true,
        mensaje: 'Obteniendo items...',
      });
      setFecha(new Date(location.state.fecha).toISOString().slice(0, 10));
      const res = await getBitacorasDateService({
        date: fecha,
      });
      setItems(res);
      const response = await getCatalogosService();
      setItems({
        primerTurno: response.filter(it => it.descripcion.includes('8')).map(item => ({ ...item, estado: false })),
        segundoTurno: response.filter(it => it.descripcion.includes('12')).map(item => ({ ...item, estado: false })),
      });
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        estado: false,
        mensaje: '',
      });
    }
  };

  const obtenerListaCheked = (turno, estado, item) => {
    if (turno === 'primero') {
      setItems(values => ({
        ...values,
        primerTurno: values.primerTurno.map(tn => (tn.id === item.id ? { ...item, estado } : tn)),
      }));
    } else if (turno === 'segundo') {
      setItems(values => ({
        ...values,
        segundoTurno: values.segundoTurno.map(tn => (tn.id === item.id ? { ...item, estado } : tn)),
      }));
    }
  };

  const guardarItems = async () => {
    try {
      setCargando({
        estado: true,
        mensaje: 'Guardando items...',
      });
      const requestData = {
        fecha: location.state.fecha ? new Date(location.state.fecha) : new Date(),
        items: [...items.primerTurno, ...items.segundoTurno].filter(it => it.estado).map(it => ({ id: it.id })),
      };
      await addBitacorasService(requestData);
      navigate(routes.bitacora);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        estado: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    setItems({
      primerTurno: items.primerTurno.map(it => ({ ...it, estado: false })),
      segundoTurno: items.segundoTurno.map(it => ({ ...it, estado: false })),
    });
  }, [fecha]);

  useEffect(() => {
    obtenerItems();
    return () => {
      setFecha(new Date().toISOString().slice(0, 10));
      setPestana('primero');
      setItems(turnos => ({
        primerTurno: turnos.primerTurno.map(it => ({ ...it, estado: false })),
        segundoTurno: turnos.segundoTurno.map(it => ({ ...it, estado: false })),
      }));
      setCargando({
        estado: false,
        mensaje: '',
      });
    };
  }, []);

  return (
    <Container className="revisiones d-flex flex-column">
      {cargando.estado && <Loading titulo={cargando.mensaje} />}
      <ErrorAlert />
      <Title titulo="Revisiones" ruta="" />
      <Row className="px-2 my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Form.Group>
            <Form.Label>Fecha</Form.Label>
            <Form.Control name="fecha" type="date" value={fecha} onChange={({ target }) => setFecha(target.value)} />
          </Form.Group>
        </Col>
      </Row>
      <Row className="px-2">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Tabs activeKey={pestana} onSelect={pest => setPestana(pest)} className="mb-3">
            <Tab eventKey="primero" title="Turno 8 hrs">
              {items.primerTurno?.length > 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="revisiones__subtitulo-tab">Items</p>
                    <Button className="text-white" variant="primary" size="sm" onClick={() => guardarItems()}>
                      Guardar
                    </Button>
                  </div>
                  {items.primerTurno.map(it => (
                    <div className="revisiones__item">
                      <p className="revisiones__item-texto" key={it.id}>
                        {it.descripcion}
                      </p>
                      <Form>
                        <Form.Check
                          type="checkbox"
                          id={`item-primero-${it.id}`}
                          checked={it.estado}
                          onChange={({ target }) => obtenerListaCheked('primero', target.checked, it)}
                        />
                      </Form>
                    </div>
                  ))}
                </>
              ) : (
                <p className="revisiones__mensaje-no-bitacoras">No hay items registrados</p>
              )}
            </Tab>
            <Tab eventKey="segundo" title="Turno 12 hrs">
              {items.segundoTurno?.length > 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="revisiones__subtitulo-tab">Items</p>
                    <Button className="text-white" variant="primary" size="sm" onClick={() => guardarItems()}>
                      Guardar
                    </Button>
                  </div>
                  {items.segundoTurno.map(it => (
                    <div className="revisiones__item">
                      <p className="revisiones__item-texto" key={it.id}>
                        {it.descripcion}
                      </p>
                      <Form>
                        <Form.Check
                          type="checkbox"
                          id={`item-segundo-${it.id}`}
                          checked={it.estado}
                          onChange={({ target }) => obtenerListaCheked('segundo', target.checked, it)}
                        />
                      </Form>
                    </div>
                  ))}
                </>
              ) : (
                <p className="revisiones__mensaje-no-bitacoras">No hay items registrados</p>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
