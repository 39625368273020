import http from '../utils/http';

export const authService = async (dataRequest, addUsuario, addRole) => {
  try {
    const { data } = await http.post('Auth/login', dataRequest);
    localStorage.setItem('credenciales', JSON.stringify(data));
    addUsuario(data.usuario);
    addRole(data.roles[0].toLowerCase());
  } catch (ex) {
    throw ex;
  }
};

export const logoutService = (removeUsuario, removeRole) => {
  removeUsuario(null);
  removeRole(null);
  localStorage.removeItem('credenciales');
};
