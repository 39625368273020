import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Loading from '../components/Loading';
import AlertaModal from '../components/modals/AlertaModal';
import Title from '../components/Title';
import { routes } from '../constants/routes';
import useError from '../hooks/useError';
import { addBitacorasService, deleteBitacorasService, getBitacorasService } from '../services/bitacoras.service';
import { getDetalleReporteService } from '../services/reporte.service';

import '../styles/Bitacora.scss';

export default function Bitacora() {
  const [estadoModal, setEstadoModal] = useState(false);
  const [itemSeleccionado, setItemSeleccionado] = useState({
    id: 0,
    fecha: '',
    cataLogoItemId: 0,
    item: '',
    fechaRegistro: '',
  });
  const [fecha, setFecha] = useState(new Date().toISOString().slice(0, 10));
  const [pestana, setPestana] = useState('primero');
  const [cargando, setCargando] = useState({
    estado: false,
    titulo: '',
  });
  const [bitacorasCompletas, setBitacorasCompletas] = useState([]);
  const [bitacoras, setBitacoras] = useState({
    primerTurno: [],
    segundoTurno: [],
  });
  const navigate = useNavigate();
  const { setError, ErrorAlert } = useError();
  const redireccionarFecha = () => {
    navigate(routes.revisiones, {
      state: {
        fecha: fecha,
      },
    });
  };
  const obtenerBitacoras = async () => {
    try {
      setCargando({
        estado: true,
        titulo: 'Obteniendo bitacoras...',
      });
      setBitacorasCompletas(await getBitacorasService());
      const response = await getDetalleReporteService({
        fechaDesde: new Date(fecha),
        fechaHasta: new Date(fecha),
      });
      setBitacoras({
        primerTurno: response.items.filter(it => it.descripcion.includes('8')),
        segundoTurno: response.items.filter(it => it.descripcion.includes('12')),
      });
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        estado: false,
        titulo: '',
      });
    }
  };

  const editarItem = async (itemLabel, itemSeleccionado) => {
    try {
      const itemEncontrado = bitacorasCompletas.find(
        it => it.item.toLowerCase() === itemSeleccionado.descripcion.toLowerCase() && fecha === it.fecha.slice(0, 10)
      );
      if (itemLabel.toLowerCase() === 'add') {
        setCargando({
          estado: true,
          titulo: 'Guardando item...',
        });
        const requestData = {
          fecha: new Date(fecha),
          items: [
            {
              id: itemEncontrado.cataLogoItemId,
            },
          ],
        };
        await addBitacorasService(requestData);
        obtenerBitacoras();
      } else if (itemLabel.toLowerCase() === 'remove') {
        setCargando({
          estado: true,
          titulo: 'Eliminando item...',
        });
        await deleteBitacorasService(itemEncontrado.id);
        obtenerBitacoras();
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        estado: false,
        titulo: '',
      });
    }
  };

  const recuperarItem = async itemRecuperado => {
    try {
      setEstadoModal(false);
      setCargando({
        estado: true,
        titulo: 'Eliminando Item',
      });
      await deleteBitacorasService(itemRecuperado.id);
      if (itemRecuperado.item.includes('8')) {
        setBitacoras(bitacoras => ({
          ...bitacoras,
          primerTurno: bitacoras.primerTurno.filter(it => it.id !== itemRecuperado.id),
        }));
      } else {
        setBitacoras(bitacoras => ({
          ...bitacoras,
          segundoTurno: bitacoras.segundoTurno.filter(it => it.id !== itemRecuperado.id),
        }));
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        estado: false,
        titulo: '',
      });
    }
  };

  useEffect(() => {
    obtenerBitacoras();
  }, [fecha]);

  useEffect(() => {
    obtenerBitacoras();
    return () => {
      setBitacoras([]);
      setPestana('primero');
      setCargando({
        estado: false,
        titulo: '',
      });
      setItemSeleccionado({
        id: 0,
        fecha: '',
        cataLogoItemId: 0,
        item: '',
        fechaRegistro: '',
      });
    };
  }, []);

  return (
    <Container className="avance d-flex flex-column">
      {cargando.estado && <Loading titulo={cargando.titulo} />}
      <ErrorAlert />
      <Title titulo="Bitácora" />
      <Row className="px-3 my-4">
        <Col className="p-0" xs={7} sm={7} md={3} lg={3}>
          <Form.Group>
            <Form.Label>Fecha</Form.Label>
            <Form.Control name="fecha" type="date" value={fecha} onChange={({ target }) => setFecha(target.value)} />
          </Form.Group>
        </Col>
        <Col className="p-0 d-flex align-items-end justify-content-end" xs={5} sm={5} md={2} lg={2}>
          <Button className="text-white" variant="primary" onClick={() => redireccionarFecha()}>
            Revisar Equipo
          </Button>
        </Col>
      </Row>
      <Row className="px-2">
        <Col xs={12} sm={12} md={5} lg={5}>
          <Tabs activeKey={pestana} onSelect={pest => setPestana(pest)} className="mb-3">
            <Tab eventKey="primero" title="Turno 8 hrs">
              {bitacoras.primerTurno?.length > 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="bitacora__subtitulo-tab">Items</p>
                  </div>
                  {bitacoras.primerTurno.map(bit => (
                    <div className="bitacora__contenedor-item my-3">
                      <p className="my-0 mx-2" key={bit.id}>
                        {bit.descripcion}
                      </p>
                      <InputGroup>
                        <Button
                          className="bitacora__form-button"
                          variant="primary"
                          onClick={() => editarItem('remove', bit)}
                        >
                          <RemoveIcon />
                        </Button>
                        <Form.Control name="cantidadItems" type="number" value={bit.cantidad} disabled />
                        <Button
                          className="bitacora__form-button"
                          variant="primary"
                          onClick={() => editarItem('add', bit)}
                        >
                          <AddIcon />
                        </Button>
                      </InputGroup>
                    </div>
                  ))}
                </>
              ) : (
                <p className="bitacora__mensaje-no-bitacoras">No hay bitácoras registradas.</p>
              )}
            </Tab>
            <Tab eventKey="segundo" title="Turno 12 hrs">
              {bitacoras.segundoTurno?.length > 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="bitacora__subtitulo-tab">Items</p>
                  </div>
                  {bitacoras.segundoTurno.map(bit => (
                    <div className="bitacora__contenedor-item my-3">
                      <p className="my-0 mx-2" key={bit.id}>
                        {bit.descripcion}
                      </p>
                      <InputGroup>
                        <Button
                          className="bitacora__form-button"
                          variant="primary"
                          onClick={() => editarItem('remove', bit)}
                        >
                          <RemoveIcon />
                        </Button>
                        <Form.Control name="cantidadItems" type="number" value={bit.cantidad} disabled />
                        <Button
                          className="bitacora__form-button"
                          variant="primary"
                          onClick={() => editarItem('add', bit)}
                        >
                          <AddIcon />
                        </Button>
                      </InputGroup>
                    </div>
                  ))}
                </>
              ) : (
                <p className="bitacora__mensaje-no-bitacoras">No hay bitácoras registradas.</p>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <AlertaModal
        estadoModal={estadoModal}
        cambiarEstadoModal={setEstadoModal}
        dataModal={itemSeleccionado}
        tituloModal="Eliminar Item Bitácora"
        respuestaModal={recuperarItem}
      />
    </Container>
  );
}
