import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function AlertaModal(props) {
  const { estadoModal, cambiarEstadoModal, tituloModal, dataModal, respuestaModal } = props;

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{tituloModal}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="fw-normal">
          ¿Está seguro que desea eliminar <span className="fw-bold">{dataModal.item}</span>?
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={() => respuestaModal(dataModal)}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AlertaModal.defaultProps = {
  tituloModal: '',
};

AlertaModal.propTypes = {
  estadoModal: PropTypes.bool.isRequired,
  cambiarEstadoModal: PropTypes.func.isRequired,
  tituloModal: PropTypes.string,
  dataModal: PropTypes.object.isRequired,
  respuestaModal: PropTypes.func.isRequired,
};
